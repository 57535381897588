import React from "react";
import Image from "./Image/Image";

import * as styles from "./Information.module.css";

const Information = props => {
  return (
    <div className={styles.Information}>
      <div className={styles.containerMiddle}>
        <h3 className={styles.quoteHeader}>{props.sectionName}</h3>
        <div className={styles.mainText}>
          <p>
            Planujesz Ślub i Wesele, wszystko ma być piękne i wyjątkowe ale
            stresuje Cię myśl o pierwszym tańcu ?
          </p>
          <p>
            Idealnie trafiłeś - Szkoła Tańca Dance Flow ma kilku genialnych
            instruktorów zajmujących się nauką Pierwszego Tańca, każdy taniec
            lub choreografia jest wyjątkowa i starannie przygotowana -
            specjalnie dla was i tak żeby wam było dobrze :)
          </p>
          <p>
            Częstotliwość i liczbę zajęć dobieramy indywidualnie pod potrzeby
            Pary.
          </p>
          <p>Spełniamy Taneczne Marzenia - Spełnimy Też Twoje :)</p>
          <p>
            Zadzwoń i zapytaj tel. 
            <span className={styles.highlighted}> 783952086</span>
          </p>
        </div>
      </div>
      <div className={styles.mainContent}>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Weronika i Adrian
            <br />
            15 czerwca 2019
          </h3>
          <p>{props.mainText[0]}</p>
        </blockquote>
        <div className={styles.image}>
          <Image className={styles.image2} img={props.images[0]} />
          <Image className={styles.image2} img={props.images[1]} />
          <Image className={styles.image2} img={props.images[2]} />
        </div>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Dominika i Tomek <br />
            27 października 2018, Wrocław
          </h3>
          <p>{props.mainText[1]}</p>
        </blockquote>
        <div className={styles.image}>
          <Image className={styles.image2} img={props.images[3]} />
          <Image className={styles.image2} img={props.images[4]} />
        </div>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Agata i Mateusz <br />6 stycznia 2017, Wrocław
          </h3>
          <p>{props.mainText[2]}</p>
        </blockquote>
        <div className={styles.image}>
          <Image className={styles.image2} img={props.images[5]} />
          <Image className={styles.image2} img={props.images[6]} />
        </div>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Pamela i Paweł <br /> 24 września 2016, Lubin
          </h3>
          <p>{props.mainText[3]}</p>
        </blockquote>
        <div className={styles.image}>
          <Image className={styles.image2} img={props.images[7]} />
          <Image className={styles.image2} img={props.images[8]} />
          <Image className={styles.image2} img={props.images[9]} />
        </div>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Gosia i Kamil <br />9 lipca 2016, Wrocław
          </h3>
          <p>{props.mainText[4]}</p>
        </blockquote>
        <blockquote
          className={[styles.danceflowColor, styles.information].join(" ")}
        >
          <h3 className={styles.quoteHeader}>
            Asia i Paweł
            <br />8 października 2016, Lubin
          </h3>
          <p>{props.mainText[5]}</p>
        </blockquote>
      </div>
    </div>
  );
};

export default Information;
