export const LEVELS = {
  LEVEL_0: "P0",
  LEVEL_0_1: "P0 / P1",
  LEVEL_1: "P1",
  LEVEL_1_2: "P1 / P2",
  LEVEL_2: "P2",
  LEVEL_2_3: "P2 / P3",
  LEVEL_3: "P3",
  OPEN: "Open",
  CHILDREN: "Dzieci",
  AGE_3_5: "3-5 lat",
  AGE_4_6: "4-6 lat",
  AGE_6_8: "6-8 lat",
  AGE_7_9: "7-9 lat",
  AGE_7_10: "7-10 lat",
  AGE_8_11: "8-11 lat",
  AGE_10_15: "10-15 lat",
  AGE_7_13: "7-13 lat",
  AGE_12_17: "12-17 lat",
  AGE_14_18: "14-18 lat", 
  LEVEL_S: "S",
  LEVEL_Z: "Z",
  DANCE_FORMATION: "Formacja taneczna"
};
