import React from "react";
import * as styles from "./Image.module.css";

const Image = props => {
  return (
    <div className={styles.container}>
      <img
        alt={props.img.toString()}
        width="250"
        className={styles.Image}
        src={require("../../../../../assets/images/" + props.img)}
      />
    </div>
  );
};

export default Image;
