import React, { Component } from "react";
import Header from "./Header/Header";
import Card from "./Card/Card";
import { connect } from "react-redux";
import * as styles from "./Prices.module.css";

class Prices extends Component {
  render() {
    return (
      <div className={styles.Prices}>
        <Header />
        <div>
          <div className={styles.partners}>
            <h3>Partnerzy</h3>
            <p>HONORUJEMY KARTY</p>
            <p>
              <span className={styles.price}>multisport plus </span> - można
              uczęszczać bez dopłaty na wszystkie zajęcia grupowe w naszej
              ofercie! (kartę można odbić co 60 minut przed każdymi zajęciami)
            </p>
            <p>
              <span className={styles.price}>multisport senior </span> - można
              uczęszczać bez dopłaty na wszystkie zajęcia grupowe w naszej
              ofercie do godziny 16.00 (kartę można odbić co 60 min przed
              każdymi zajęciami)
            </p>
            <p>
              <span className={styles.price}>medicover sport </span> - bez dopłaty
              zgodnie z taryfą medicover sport{" "}
            </p>
            <p>
              Warunkiem korzystania z kart partnerskich jest wpłacenie kaucji w
              wysokości 30 zł. Dzięki kaucji będziesz miał zawsze pewne miejsce
              w grupie. Kaucja jest jedna, niezależnie od liczby kursów, na
              które jesteś zapisany.
            </p>
            <img
              src={require("../../assets/images/Partners/" +
                "benefitSystem.png")}
              alt="partner1"
            />
            <img
              src={require("../../assets/images/medicoversport.png")}
              alt="partner2"
            />
          </div>
          <div>
            <h3>Karnety</h3>
          </div>
          <div className={styles.carnets}>
            {this.props.prices.map((price, index) => {
              return (
                <Card
                  key={index}
                  name={price.name}
                  price={price.price}
                  description={price.description}
                  singlePrice={price.singlePrice}
                />
              );
            })}
          </div>
          <h1 className={styles.price}>Karnet ważny 4 tygodnie od dnia zakupu.</h1>
          <div className={styles.mainHeading + " " + styles.paddingy}>
            <h2>
              Jednorazowe wejście na zajęcia -
              <span className={styles.price}> 40zł</span>
            </h2>
            <h2>
              Karnet OPEN miesięczny na 4 dowolnie wybrane zajęcia w naszej ofercie (ważny od dnia zakupu) -
              <span className={styles.price}> 155zł</span>
            </h2>
            <h2>
            Karnet OPEN miesięczny bez limitu na wszystkie zajęcia w naszej ofercie (ważny od dnia zakupu) - <span className={styles.price}>379zł</span>
            </h2>
          </div>
          <h3 className={styles.paddingy}>Zajęcia indywidualne / Pierwszy Taniec z instruktorem</h3>
          <div className={styles.danceWithInstructor}>
            {this.props.individualLessons.map((price, index) => {
              return (
                <Card
                  key={index}
                  name={price.name}
                  price={price.price}
                  description={price.description}
                  singlePrice={price.singlePrice}
                />
              );
            })}
          </div>
          <h3 className={styles.paddingy}>Zajęcia indywidualne PRO - AM</h3>
          <div className={styles.proAmator}>
            {this.props.proAm.map((price, index) => {
              return (
                <Card
                  key={index}
                  name={price.name}
                  price={price.price}
                  description={price.description}
                  singlePrice={price.singlePrice}
                />
              );
            })}
          </div>

          <h3 className={styles.paddingy}>Wynajem sal od poniedziałku do piątku</h3>
          <div className={styles.roomRental}>
            {this.props.roomRenting.map((price, index) => {
              return (
                <Card
                  key={index}
                  name={price.name}
                  price={price.price}
                  description={price.description}
                />
              );
            })}
          </div>
            <h3 className={styles.studentHall}>Jeśli jesteś naszym uczniem i potrzebujesz sali na trening zapytaj o ofertę specjalną w recepcji szkoły lub zadzwoń.</h3>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    prices: state.pricesReducer.prices,
    individualLessons: state.pricesReducer.individualLessons,
    proAm: state.pricesReducer.proAm,
    roomRenting: state.pricesReducer.roomRenting
  };
};

export default connect(mapStateToProps)(Prices);
