export const ENROLLMENT_STATUSES = {
  OPEN: {
    status: "OPEN",
    text: "zbieramy zapisy!",
    buttonText: "zapisz się!"
  },
  CLOSED: {
    status: "CLOSED",
    text: "zapisy zamknięte!",
    buttonText: "brak miejsc!"
  },
  JOIN: {
    status: "JOIN",
    text: "dołącz do grupy!",
    buttonText: "dopisz się!"
  }
};
