import React from "react";
import * as styles from "./Legend.module.css";

const Legend = props => {
  return (
    <div className={styles.Legend}>
      <ul className={styles.dances}>
        <li>Tańce:</li>
        <li>
          <span className={styles.solo}>SOLO</span>
        </li>
        <li>
          <span className={styles.inPairs}>W PARACH</span>
        </li>
        <li>
          <span className={styles.danceFormation}>FORMACJE TANECZNE</span>
        </li>
        <li>
          <span className={styles.kids}>DLA DZIECI</span>
        </li>
      </ul>
      <ul className={styles.levels}>
        <li>Poziomy:</li>
        <li>P0 - do 2 miesięcy</li>
        <li>P1 - od 2 do 6 miesięcy</li>
        <li>P2 - od 6 do 9 miesięcy</li>
        <li>P3 - od 9 miesięcy do 1.5 roku</li>
        <li>S - od 1.5 roku do 3 lat</li>
        <li>Z - ponad 3 lata</li>
      </ul>
      <ul className={styles.levels}>
        <li>Status:</li>
        <li>Zapisz się! - oznacza, że zbieramy zapisy przed startem powiadomimy wszystkich zapisanych o terminie rozpoczęcia zajęć</li>
        <li>Dopisz się! - oznacza, że zajęcia już wystartowały, są miejsca można się dopisać i przyjść</li>
      </ul>
      </div>
  );
};

export default Legend;
