import React from "react";
import * as styles from "./Header.module.css";
import HeaderSlider from "./HeaderSlider";

const Header = props => {
  return (
    <div>
      {props.children}
      <div className={styles.Header}>
        <div className={styles.background}>&nbsp;</div>
        <div className={styles.bgdesktop}>&nbsp;</div>
        <div className={styles.slider}>
          <HeaderSlider sliderImages={props.headerSliderImages}/>
        </div>
        <div className={styles.text}>
          <p>Szkoła tańca Dance Flow</p>
          <p>ul. Rydygiera 15 Wrocław</p>
          <p>Tel. 783952086</p>
        </div>
      </div>
      <div className={[styles.section10, styles.demo].join(" ")}>
        <a href="#thanks">
          <span>&nbsp;</span>
        </a>
      </div>
      <div className={styles.arrow}> &darr;</div>
    </div>
  );
};

export default Header;
