import React, { Component } from "react";
import Header from "./Header/Header";
import * as styles from "./DanceShows.module.css";

import { connect } from "react-redux";

class DanceShows extends Component {
  render() {
    let dance = this.props.danceShow[0];
    return (
      <div>
        <Header
          headerMainText={dance.headerText}
          headerLowerText={dance.headerLowerText}
          headerLogoBlackWhite={dance.headerLogoPath}
        />
        <div className={styles.mainSection}>
          <p> {dance.text[0]}</p>
          <p>
            <span className={styles.highlighted}>&bull;</span> {dance.text[1]}
          </p>
          <p>
            <span className={styles.highlighted}>&bull;</span> {dance.text[2]}
          </p>
          <p>
            <span className={styles.highlighted}>&bull;</span> {dance.text[3]}
          </p>
          <p>
            <span className={styles.highlighted}>&bull;</span> {dance.text[4]}
          </p>

          <div className={styles.images} >
            <div><img alt="img" width="100%" src={require("../../../assets/images/" + dance.images[0]) } /></div>
            <div><img alt="img" width="100%" src={require("../../../assets/images/" + dance.images[1]) } /></div>
            <div><img alt="img" width="100%" src={require("../../../assets/images/" + dance.images[2]) } /></div>
            <div><img alt="img" width="100%" src={require("../../../assets/images/" + dance.images[3]) } /></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    danceShow: state.danceShowReducer.danceShow
  };
};

export default connect(mapStateToProps)(DanceShows);
