import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as styles from "../Navigation.module.css";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/navigationActions";

class SubNavigation extends Component {
  render() {
    return (
      <>
        <button
          onClick={this.props.onToggleNavigation}
          className={[styles.link, styles.navButton].join(" ")}
        >
          Oferta dodatkowa
        </button>

        {this.props.toggled ? (
          <div className={styles.item}>
            <div className={styles.dropdownList}>
              <ul>
                {this.props.items.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      exact
                      to={item.path}
                      state={item.id}
                      className={styles.link}
                      activeClassName={styles.active}
                      onClick={() => this.props.clicked()}
                    >
                      {item.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch(actions.toggleNavigation())
  };
};

const mapStateToProps = state => {
  return {
    toggled: state.userInterfaceReducer.toggled
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubNavigation);
