import React, { Component } from "react";
import * as styles from "./Card.module.css";

class Card extends Component {
  render() {
    let filledCard = (
      <div className={styles.Card}>
        <div className={styles.content}>
          <div
            className={[styles.link, styles.danceName].join(" ")}
            onClick={() => this.props.navigateToDance(this.props.link)}
          >
            <p>{this.props.courseName}</p>
          </div>
          <div className={styles.time}>
            <p>{this.props.time}</p>
          </div>
          <div className={styles.level}>
            <p>{this.props.level}</p>
          </div>
          {this.props.instructor.name !== undefined ? (
            <div
              className={styles.link}
              onClick={() =>
                this.props.navigateToInstructor(this.props.instructor.id)
              }
            >
              <p>{this.props.instructor.name}</p>
            </div>
          ) : (
            <div className={styles.link}>
              <p>{this.props.instructor}</p>
            </div>
          )}
        </div>

        {this.props.info.status !== "CLOSED" ? (
          <a
            href="#form"
            onClick={() => {
              this.props.shouldPartnerNameBeVisible(this.props.isDanceInPairs);
              this.props.openPopup();
              this.props.setCurrentFormData(
                this.props.courseName,
                this.props.time,
                this.props.day
              );
            }}
            className={[styles.btn, styles.link].join(" ")}
          >
            {this.props.info.buttonText}
          </a>
        ) : (
          <a href="_" className={[styles.btn, styles.link].join(" ")}>Brak miejsc</a>
        )}

        <div className={styles.informations}>
          <div>{this.props.info.text}</div>
        </div>
      </div>
    );
    return filledCard;
  }
}

export default Card;
