import React, { Component } from "react";
import Slider from "react-slick";
import * as styles from "./HeaderSlider.module.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class HeaderSlider extends Component {
  componentDidMount() {
    setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 5);  }

  render() {
    
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };
  
    return (
      <Slider ref="slick" className={styles.Slider} {...settings}>
        {this.props.sliderImages.map((sliderImage, index) => {
          return (
            <div key={index}><img className={styles.image} src={require("../../../assets/images/" + sliderImage.text)} alt={index}></img></div>
          );
        })}
      </Slider>
    );
  };
};

export default HeaderSlider;
