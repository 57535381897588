import React, { Component } from "react";
import Header from "./Header/Header";
import {connect} from "react-redux";
import * as styles from "./BatchParty.module.css";

class BatchParty extends Component {
 
  render() {
    let dance = this.props.batchParty[0];
    return (
      <div>
        <Header
          headerMainText={dance.headerText}
          headerLowerText={dance.headerLowerText}
          headerLogoBlackWhite={dance.headerLogoPath}
        />
        <div className={styles.mainContent}>
          <div><h3>{dance.text[0]}</h3></div>
          <div><p>{dance.text[1]}</p></div>
          <div><p>{dance.text[2]}</p></div>
          <div className={styles.image}><img alt="dnc" src={require("../../../assets/images/abc2_mobile.jpg")} /></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      batchParty: state.batchPartyReducer.batchParty
    };
  };

export default connect(mapStateToProps)(BatchParty);
