import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import * as styles from "./DesktopNavigation.module.css";

class DesktopNavigation extends Component {

  render() {
    return (
      <div className={styles.DesktopNavigation}>
        <ul className={styles.list}>
          <li>
            <NavLink
              exact
              to={"/strona-glowna"}
              className={styles.link}
              activeClassName={styles.active}
            >
              <p>strona główna</p>
            </NavLink>
          </li>
          <li className={styles.dropdown}>
            <a id="ao" href="#ao" className={[styles.link, styles.firstChild].join(" ")}>
              oferta dodatkowa
              <div className={styles.dropdownContent}>
                <NavLink
                  exact
                  to={"/oferta-dodatkowa/pierwszy-taniec"}
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  pierwszy taniec
                </NavLink>
                <NavLink
                  exact
                  to={"/oferta-dodatkowa/wieczory-panienskie"}
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  wieczory panieńskie
                </NavLink>
                <NavLink
                  exact
                  to={"/oferta-dodatkowa/pokazy-tanca"}
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  pokazy tańca
                </NavLink>
                <NavLink
                  exact
                  to={"/oferta-dodatkowa/zajecia-indywidualne"}
                  className={styles.link}
                  activeClassName={styles.active}
                >
                  zajecia indywidualne / PRO-AM 
                </NavLink>
              </div>
            </a>
          </li>
          <li>
            <NavLink
              exact
              to={"/grafik"}
              className={styles.link}
              activeClassName={styles.active}
            >
              grafik
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={"/cennik"}
              className={styles.link}
              activeClassName={styles.active}
            >
              cennik
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to={"/kontakt"}
              className={styles.link}
              activeClassName={styles.active}
            >
              kontakt
            </NavLink>
          </li>
        </ul>
        <div className={styles.logoBox}>
          <img
            src={require(`../../assets/images/danceflow-white-1.png`)}
            alt="Logo"
            className={styles.logo}
          />
        </div>
      </div>
    );
  }
}

export default DesktopNavigation;
