import React from "react";
import * as styles from "./Dance.module.css";

import { NavLink } from "react-router-dom";

const Dance = (props) => {
  return (
    <li>
      <div
        data-aos="zoom-in-up"
        data-aos-duration="800"
        className={styles.Dance}
      >
        <div className={styles.danceName}>
          <NavLink
            exact
            to={props.link}
            className={styles.link}
            activeClassName={styles.active}
          >
            {props.name}
          </NavLink>
        </div>
      </div>
    </li>
  );
};

export default Dance;
