import React from "react";
import * as styles from "./HeaderDescription.module.css";

const HeaderDescription = props => {
  return (
    <div className={styles.HeaderDescription}>
      <div className={styles.content}>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default HeaderDescription;
