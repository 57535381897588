import React, { Component } from "react";
import * as styles from "./Schedule.module.css";
import Header from "./Header/Header";
import MainContent from "./MainContent/MainContent";
import Legend from "./Legend/Legend";
import { connect } from "react-redux";

class Schedule extends Component {
  navigateToDance = courseName => {
    this.props.history.push(`${courseName}`);
  };

  navigateToInstructor = instructorId => {
    this.props.history.push(`/instruktor/${instructorId}`);
  };

  render() {
    return (
      <div className={styles.Schedule}>
        <Header />
        <div className={styles.legend}>
          <Legend />
        </div>
        <MainContent
          navigateToDance={this.navigateToDance}
          navigateToInstructor={this.navigateToInstructor}
          dances={this.props.dances}
          instructors={this.props.instructors}
          scheduleItems={this.props.scheduleItems}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dances: state.dancesReducer.dances,
    instructors: state.instructorsReducer.instructors,
    scheduleItems: state.scheduleReducer.scheduleItems
  };
};

export default connect(mapStateToProps)(Schedule);
