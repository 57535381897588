const HEADER_LOGO_BLACK = "danceflow-white-black-1.png";

const initialState = {
  individualDances: [
    {
      name: "zajecia-indywidualne",
      headerText: "zajęcia",
      headerLowerText: "indywidualne / PRO-AM",
      headerLogoPath: HEADER_LOGO_BLACK,
      sectionName: "Zajęcia indywidualne / PRO-AM",
      text: [
        ` `
      ]
    }
  ]
};
const individualDancesReducer = (state = initialState, action) => {
  return state;
};

export default individualDancesReducer;
