import React from "react";
import * as styles from "./Header.module.css";

const Header = props => {
  return (
    <div className={styles.header}>
      <div className={styles.textBox}>
        <div data-aos="zoom-in" className={styles.text}>
          <span className={styles.textDance}>Grafik</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
