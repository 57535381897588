import React from "react";
import * as styles from "./Footer.module.css";
import "../../index.module.css";
import { Link } from "react-router-dom";

export default props => {
  return (
    <div className={styles.footer}>
      <div className={styles.row}>
        <div>
          <div className={styles.navigation}>
            <h3>Kontakt</h3>
            <ul className={styles.list}>
              <li>ul. Rydygiera 15 Wrocław</li>
              <li>kontakt@danceflow.pl</li>
              <li>Telefon: +48 783 952 086</li>
            </ul>
          </div>
        </div>
        <div>
          <div className={[styles.navigation, styles.ulist].join(" ")}>
            <h3>Godziny zajęć</h3>
            <ul>
              <li>
                <div>Poniedziałek</div> <div>16:00 – 22:00</div>
              </li>
              <li>
                <div>Wtorek</div> <div>16:00 – 22:00</div>
              </li>
              <li>
                <div>Środa</div> <div>16:00 – 22:00</div>
              </li>
              <li>
                <div>Czwartek</div> <div>16:00 – 22:00</div>
              </li>
              <li>
                <div>Piątek</div> <div>16:00 – 22:00</div>
              </li>
              <li>
                <div>Soboty </div>
                <div>11:30 - 16:00</div>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className={styles.navigation}>
            <h3>Nawigacja</h3>
            <ul className={styles.list}>
              <li className={styles.item}>
                <Link exact="true" to={"/grafik"}>
                  Grafik
                </Link>
              </li>
              <li className={styles.item}>
                <Link exact="true" to={"/cennik"}>
                  Cennik
                </Link>
              </li>
              <li className={styles.item}>
                <Link exact="true" to={"/kontakt"}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className={styles.navigation}>
            <h3>Media społecznościowe</h3>
            <ul className={styles.list}>
              <li className={styles.item}>
                <a href={"https://www.facebook.com/danceflowwroclaw/"}>
                  Facebook
                </a>
              </li>
              <li className={styles.item}>
                <a
                  href={
                    "https://www.youtube.com/channel/UC4thrN2HCuMIJIdUnao0y0Q"
                  }
                >
                  YouTube
                </a>
              </li>
              <li />
            </ul>
          </div>
        </div>
        <div className={styles.iframeContent}>
          <iframe
            title="fb"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdanceflowwroclaw%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="340"
            className={styles.iframeMedia}
            height="500"
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
        </div>
      </div>
      <div className={styles.logoBox}>
        <img
          src={require("../../assets/images/" + props.imageUrl)}
          alt="Logo"
          className={styles.logo}
        />
      </div>
      <div className={styles.copyright}>Copyrights Dance Flow &copy; 2018</div>
    </div>
  );
};
