import React from "react";
import * as styles from "./HeaderImage.module.css";

const HeaderImage = props => {
  const image = props.image;
  return (
    <div
      className={styles.HeaderImage}
      style={{
        backgroundImage:
          `url(` + require("../../../assets/images/" + image) + `)`
      }}
    >
      &nbsp;
      <div className={styles.navi}>
        <div className={styles.arrowLeft} onClick={props.switchInstructorLeft}>
          {" "}
          &lt;{" "}
        </div>
        <div
          className={styles.arrowRight}
          onClick={props.switchInstructorRight}
        >
          {" "}
          &gt;{" "}
        </div>
      </div>
    </div>
  );
};

export default HeaderImage;
