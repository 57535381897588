import React from "react";
import * as styles from "./Card.module.css";
import { NavLink } from "react-router-dom";

const Card = props => {
  return (
    <div>
      <NavLink
        style={{ textDecoration: "none" }}
        className={styles.navLink}
        to={"/instruktor/" + props.id}
      >
        <div className={styles.Card}>
          <div className={styles.name}>
            <h2>{props.name}</h2>
          </div>
          <div className={styles.content}>
            <p>{props.shortDescription}</p>
          </div>
          <div
            className={styles.image}
            style={{
              backgroundImage:
                ` url(` +
                require("../../../../assets/images/" + props.image) +
                `)`
            }}
          >
            &nbsp;
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default Card;
