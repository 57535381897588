import React, { Component } from "react";
import * as styles from "./MainContent.module.css";
import Card from "./Card/Card";
import "./CardColors.css";

import CardMobile from "./Card/CardMobile";
import { SCHEDULE_HOURS } from "../../../consts/scheduleTimes";
import { SCHEDULE_DAYS } from "../../../consts/scheduleTimes";
import Popup from "./Popup/Popup";

class MainContent extends Component {
  state = {
    popupOpen: false,
    danceName: "",
    time: "",
    day: "",
    isDanceInPairs: false,
  };

  togglePopup = (e) => {
    this.setState({ popupOpen: true });
  };

  closePopup = (e) => {
    this.setState({ popupOpen: false });
  };

  setCurrentFormData = (danceName, time, day) => {
    this.setState({
      danceName: danceName,
      time: time,
      day: day,
    });
  };

  shouldPartnerNameBeVisible = (shouldPartnerNameBeVisible) => {
    this.setState({
      isDanceInPairs: shouldPartnerNameBeVisible,
    });
  };

  render() {
    return (
      <div id="grafik" className={styles.MainContent}>
        <Popup
          toggled={this.state.popupOpen}
          close={this.closePopup}
          danceName={this.state.danceName}
          time={this.state.time}
          day={this.state.day}
          isDanceInPairs={this.state.isDanceInPairs}
        />

        <div className={styles.schedule}>
          <div className={styles.scheduleHeader}>
            <div className={styles.scheduleHours}>H</div>
            <div className={styles.scheduleDays}>
              <div>
                <p>poniedziałek</p>
              </div>
              <div>
                <p>wtorek</p>
              </div>
              <div>
                <p>środa</p>
              </div>
              <div>
                <p>czwartek</p>
              </div>
              <div>
                <p>piatek</p>
              </div>
              <div>
                <p>sobota</p>
              </div>
              {/* <div>
                <p>niedziela</p>
              </div> */}
            </div>
          </div>

          <div className={styles.scheduleColumn}>
            {Object.values(SCHEDULE_HOURS).map((hour, key) => {
              return (
                <div key={key} className={styles.scheduleCell}>
                  <p>{hour}</p>
                </div>
              );
            })}
          </div>

          {Object.values(SCHEDULE_DAYS).map((day, indexDay) => {
            return (
              <div key={indexDay} className={styles.scheduleColumn}>
                {Object.values(SCHEDULE_HOURS).map((time, indexTime) => {
                  let items = this.props.scheduleItems.filter(
                    (item) => item.time === time && item.day === day
                  );
                  return (
                    <div key={indexTime} className={styles.scheduleCell}>
                      {"\xa0"}
                      {items.map((item, key) => {
                        return (
                          <div key={key} className={item.color}>
                            <Card
                              key={key}
                              isDanceInPairs={item.color === "inPairs"}
                              shouldPartnerNameBeVisible={
                                this.shouldPartnerNameBeVisible
                              }
                              openPopup={this.togglePopup}
                              setCurrentFormData={this.setCurrentFormData}
                              navigateToDance={this.props.navigateToDance}
                              navigateToInstructor={
                                this.props.navigateToInstructor
                              }
                              courseName={
                                item.customName === "" ||
                                item.customName === undefined ||
                                item.customName === null
                                  ? this.props.dances.find(
                                      (dance) => dance.id === item.danceId
                                    ).name
                                  : item.customName
                              }
                              time={time}
                              instructor={
                                item.instructorName === ""
                                  ? this.props.instructors.find(
                                      (instructor) =>
                                        instructor.id === item.instructorId
                                    )
                                  : item.instructorName
                              }
                              level={item.level}
                              info={item.recruitment}
                              day={item.day}
                              link={
                                this.props.dances.find(
                                  (dance) => dance.id === item.danceId
                                )
                                  ? this.props.dances.find(
                                      (dance) => dance.id === item.danceId
                                    ).link
                                  : "grafik"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles.scheduleMobile}>
          {Object.values(SCHEDULE_DAYS).map((day, indexDay) => {
            return (
              <div key={indexDay} className={styles.scheduleMobileDays}>
                <div className={styles.scheduleMobileDay}>
                  <p>{day}</p>
                </div>
                <div className={styles.scheduleMobileListContainer}>
                  <ul className={styles.scheduleMobileList}>
                    {Object.values(SCHEDULE_HOURS).map((time, indexTime) => {
                      let items = this.props.scheduleItems.filter(
                        (item) => item.time === time && item.day === day
                      );

                      return (
                        <div key={indexTime}>
                          {items.map((item, key) => {
                            return (
                              <li
                                key={key}
                                className={[
                                  styles.scheduleMobileListItem,
                                  item.color,
                                ].join(" ")}
                              >
                                <CardMobile
                                  isDanceInPairs={item.color === "inPairs"}
                                  shouldPartnerNameBeVisible={
                                    this.shouldPartnerNameBeVisible
                                  }
                                  openPopup={this.togglePopup}
                                  navigateToDance={this.props.navigateToDance}
                                  navigateToInstructor={
                                    this.props.navigateToInstructor
                                  }
                                  courseName={
                                    item.customName === "" ||
                                    item.customName === undefined ||
                                    item.customName === null
                                      ? this.props.dances.find(
                                          (dance) => dance.id === item.danceId
                                        ).name
                                      : item.customName
                                  }
                                  time={time}
                                  instructor={
                                    item.instructorName === ""
                                      ? this.props.instructors.find(
                                          (instructor) =>
                                            instructor.id === item.instructorId
                                        )
                                      : item.instructorName
                                  }
                                  level={item.level}
                                  info={item.recruitment}
                                  setCurrentFormData={this.setCurrentFormData}
                                  day={item.day}
                                  link={
                                    this.props.dances.find(
                                      (dance) => dance.id === item.danceId
                                    )
                                      ? this.props.dances.find(
                                          (dance) => dance.id === item.danceId
                                        ).link
                                      : "grafik"
                                  }
                                />
                              </li>
                            );
                          })}
                        </div>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MainContent;
