const HEADER_LOGO_BLACK = "danceflow-white-black-1.png";

const initialState = {
  danceShow: [
    {
      name: "pokazy-tanca",
      headerText: "pokazy",
      headerLowerText: "tańca",
      headerLogoPath: HEADER_LOGO_BLACK,
      sectionName: "Pokazy tańca",
      text: [
        `Szkoła Tańca Dance Flow dysponuje różnorodną ofertą w zakresie pokazów tańca:`,
        `oferujemy zarówno pokazy w klimatach lat sześćdziesiątych czy siedemdziesiątych, jak i współczesnych stylów tańca`,
        `organizujemy również specjalne pokazy tańca na imprezy okolicznościowe takie jak urodziny, imieniny, wesela, dzień kobiet, i wiele innych`,
        `organizujemy tez pokazy tańca na kampaniach promocyjnych znanych marek, otwarciach klubów, dyskotek, restauracji`,
        `posiadamy wykwalifikowane tancerki zajmujące się zawodowo oprawą artystyczno-taneczną imprez na przykład w klubach lub na koncertach`
      ],
      images: ["instruktorzy/DSC_6773.jpg", "pokazy2.jpg", "pokazy3.jpg", "instruktorzy/DSC_6702-Edit.jpg"]
    }
  ]
};

const danceShowReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default danceShowReducer;
