import React from "react";
import * as styles from "./HeaderDances.module.css";

const HeaderDances = props => {
  const dancesLength = props.dances.length;

  let dances = (
    <div className={styles.HeaderDances}>
      {props.dances.map((dance, index) => {
        return <p key={index}> {dance} </p>;
      })}
    </div>
  );

  if (dancesLength > 5) {
    dances = (
      <>
        <div className={styles.HeaderDances}>
          {props.dances.slice(0, dancesLength / 2).map((dance, index) => {
            return <p key={index}> {dance} </p>;
          })}
        </div>
        <div className={styles.HeaderDances}>
          {props.dances.slice(dancesLength / 2).map((dance, index) => {
            return <p key={index}> {dance} </p>;
          })}
        </div>
      </>
    );
  }

  return dances;
};

export default HeaderDances;
