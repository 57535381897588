const HEADER_LOGO_BLACK = "danceflow-white-black-1.png";

const initialState = {
  batchParty: [
    {
      name: "wieczory-panienskie",
      headerText: "wieczory",
      headerLowerText: "panieńskie",
      headerLogoPath: HEADER_LOGO_BLACK,
      images: [
        "pierwszytaniec1.jpg",
        "pierwszytaniec1.jpg",
        "pierwszytaniec1.jpg"
      ],
      sectionName: "Wieczory panieńskie",
      text: [
        "Oryginalny pomysł na wieczór panieński z nauką wybranego przez was stylu tanecznego?!",
        
        `Zmysłowa burleska… uwodzicielskie sexy dance… seksowna bachata… czarujący taniec brzucha…
      a może klasyczne flamenco albo latino ?`,
      
      `Wybór pomysłu należy do was, do nas cała organizacja,
      możecie coś zaproponować albo zupełnie zdać się na nas.      
      Jeśli masz pytania albo inne propozycję zadzwoń!. `
      ]
    }
  ]
};
const batchPartyReducer = (state = initialState, action) => {
  return state;
};

export default batchPartyReducer;
