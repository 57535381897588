import React from "react";
import Header from "./Header/Header";
import Form from "../ReusableComponents/Form/Form";
import * as styles from "./Contact.module.css";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";

const Map = ReactMapboxGl({
  scrollZoom: false,
  accessToken:
    "pk.eyJ1Ijoic2hlaWx2ZWFuIiwiYSI6ImNqbmU5aXR0aDBrcHkzcWxmMmo4d241azAifQ.bLa66BcqzXgEdNy41xJ8yQ"
});

const Contact = props => {
  return (
    <div className={styles.Contact}>
      <Header logo="danceflow-white-black-1.png" />
      <div className={styles.mainSection}>
        <div className={styles.text}>
          <ul data-aos="zoom-in-up" data-aos-duration="800">
            <li>Szkoła Tańca</li>
            <li>
              Tel.
              <span className={styles.redText}> + 48 783 952 086</span>
            </li>
            <li>
              E-mail:{" "}
              <span className={styles.redText}>kontakt@danceflow.pl</span>
            </li>
            <li>
            <p>ul. Rydygiera 15 Wrocław</p>
            </li>
          </ul>
          <ul data-aos="zoom-in-up" data-aos-duration="800">
            <li>Godziny otwarcia</li>
            <li>
              <div>Poniedziałek</div> <div>16:00 – 22:00</div>
            </li>
            <li>
              <div>Wtorek</div> <div>16:00 – 22:00</div>
            </li>
            <li>
              <div>Środa</div> <div>16:00 – 22:00</div>
            </li>
            <li>
              <div>Czwartek</div> <div>16:00 – 22:00</div>
            </li>
            <li>
              <div>Piątek</div> <div>16:00 – 22:00</div>
            </li>
            <li>
              <div>Soboty </div>
              <div>11:30 - 16:00</div>
            </li>
          </ul>
          <ul data-aos="zoom-in-up" data-aos-duration="800">
            <li>Dane Firmy:</li>
            <li>Szkoła Tańca Dance Flow Klara Kupińska</li>
            <li>NIP: 6922379703</li>
            <li>ul. Rydygiera 15 Wrocław</li>
          </ul>
        </div>
        <div className={styles.form}>
          <section className={styles.sectionBook}>
            <Form />
          </section>
        </div>
        <div className={styles.map}>
          <Map
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: "70vh",
              width: "100%"
            }}
            center={[17.04392, 51.11756]}
            zoom={[18.1]}
          >
            <Layer
              type="symbol"
              id="marker"
              style={{ "background-color": "red" }}
              layout={{ "icon-image": "circle-15" }}
            >
              <Feature coordinates={[17.04392, 51.11756]} />
            </Layer>
          </Map>
        </div>
      </div>
    </div>
  );
};

export default Contact;
