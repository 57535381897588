import React from "react";
import Dance from "./Dance/Dance";
import * as styles2 from "./Dances.module.css";

const Dances = props => {
  return (
    <div className={styles2.Dances}>
      <div className={styles2.background}>&nbsp;</div>
      <h2>Tańce</h2>
      <div className={styles2.section}>
        <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                alt=""
                src={require("../../../assets/images/instruktorzy/" +
                  "DSC_6670.jpg")}
              />
            </div>
          </div>
          <div className={styles2.danceName}>Solo</div>
          <ul className={styles2.solo}>
            {props.dances.sort((a, b) => a.name.localeCompare(b.name))
              .filter(dance => dance.type.includes("solo") && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div>
        <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/instruktorzy/DSC_6954-Edit.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className={styles2.danceName}>W parach</div>
          <ul className={styles2.dancesInPair}>
            {props.dances
              .filter(dance => dance.type.includes("danceInPair") && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div>

        <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/dances/" +
                  "kids.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className={styles2.danceName}>Dla dzieci</div>
          <ul className={styles2.kids}>
            {props.dances
              .filter(dance => dance.type.includes("kids") && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div>
        <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/girls/DSC_6902-Edit-Edit.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className={styles2.danceName}>Formacje Taneczne</div>
          <ul className={styles2.danceFormation}>
            {props.dances
              .filter(dance => dance.type === "danceFormation" && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div>
        {/* <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/instruktorzy/" +
                  "DSC_6813-Edit.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className={styles2.danceName}>Soboty i Niedziele</div>
          <ul className={styles2.saturday}>
            {props.dances
              .filter(dance => dance.type.includes("saturday") && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div> */}
        {/* <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/dances/" +
                  "power-fitness.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className={styles2.danceName}>Zajęcia Poranne</div>
          <ul className={styles2.saturday}>
            {props.dances
              .filter(dance => dance.type.includes("fitness") && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>
        </div> */}

        {/* ****commented for future change of mind****
        <div className={styles2.listBox}>
          <div className={styles2.imageContainer}>
            <div className={styles2.image}>
              <img
                src={require("../../../assets/images/dances/" +
                  "power-fitness.jpg")}
                alt=""
              />
            </div>
          </div>
           
          <div className={styles2.danceName}>Zajęcia poranne</div>
          <ul className={styles2.morning}>
            {props.dances
              .filter(dance => dance.type === "morning" && dance.isActive === true)
              .map((dance, index) => {
                return (
                  <Dance
                    key={index}
                    id={dance.id}
                    class={dance.class}
                    name={dance.name}
                    link={dance.link}
                    image={dance.image}
                  />
                );
              })}
          </ul>

        </div> */}
        
      </div>
    </div>
  );
};

export default Dances;
