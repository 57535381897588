const initialState = {
  instructors: [
    {
      id: 1,
      name: "Klara",
      visibility: 1,
      shortDescription: ` `,
      description: `Tańczy od dzieciństwa ale to w 2005 roku poznała Flamenco i zatraciła się zupełnie.
            W zespole „Vengo Flamenco” doskonaliła umiejętności i licznie występowała. Następnie pojawił się Taniec Brzucha, który pokazał kolejne możliwości w sferze tanecznej. Kolejny był Taniec Towarzyski, ale w chwili gdy Klara zobaczyła Bachatę, a później Zouk, poczuła, że to jest to.
            Bierze udział w licznych warsztatach i kursach tańca, między innymi: flamenco, taniec brzucha, salsa, bachata, zouk, taniec towarzyski, tango argentino, lindy hop, taniec nowoczesny, jazz, pole dance,  zumba, techniki fitness i pracy nad ciałem.
            Od 2008 roku do 2017 prowadziła zajęcia w Szkole Tańca „CARMEN ” w Lubinie z którą jest ciągle związana.
            Od października 2010 prowadzi zajęcia taneczne z najmłodszymi w przedszkolach.
            W czerwcu 2011 roku zdobyła uprawnienia i tytuł instruktora tańca.
            Od września 2011 roku po dziś dzień instruktor tańca na Ogólnopolskich obozach adaptacyjnych – Campus Akademicki w Międzyzdrojach i Campus Akademicki w Łazach.
            Od września 2012 roku do lutego 2017 roku instruktor tańca w Akademii Tańca ” ESENS.
            Od listopada 2012 licencjonowany instruktor ZUMBA FITNESS należący do ZIN.
            W czerwcu 2015 roku obroniła dyplom i zdobyła uprawnienia Instruktor Fitness, w tym samym miesiącu uzyskała też Certyfikat udziału w szkoleniu z Dietetyki.
            Od lutego 2016 roku do lutego 2017 roku Instruktor Tańca w Szkole Tańca La Rosa Negra.
            Od lutego 2016 roku do marca 2017 roku trener i instruktor w Fitness Klubie Ultrafit.
            I wreszcie od maja 2017 roku właściciel Szkoły Tańca "Dance Flow"!!!
            Obecnie w szkole tańca Dance Flow - trenerka i nauczycielka dzieci i dorosłych. Główny choreograf formacji tanecznej Flow Girls i Shams-Amar.
             
            Po latach zdobywania doświadczenia jako tancerka i nauczycielka, otworzyłam miejsce które kocham i które ma za zadanie zarazić was magią tańca w każdym wieku i w każdej chwili waszego życia! Szkolimy dzieci, młodzież, dorosłych i seniorów.
            Taniec daje radość, wolność i odrobinę zatracenia od codziennych trosk.
             
            Moje motto brzmi: „Gdy tańczę wiem, że wolno mi wszystko!”`,
      headerImg: "instruktorzy/DSC_6672.jpg",
      headerDances: [
        "Bachata",
        "Balet",
        "Flamenco",
        "Flow Girls",
        "Latino solo",
        "Pierwszy taniec",
        "Sexy dance",
        "Taniec brzucha",
        "Taniec użytkowy",
        "Zouk"
      ],
      image: "instruktorzy/DSC_6672.jpg"
    },
    // {
    //   id: 3,
    //   name: "Basia",
    //   visibility: 1,
    //   shortDescription: ` `,
    //   description: `Przygodę z tańcem rozpoczęła mając 7 lat. Przez 6 lat tańczyła disco dance w szkolnym zespole, a po jej ukończeniu kontynuowała swą pasję w Studio Tańca Fuego w Lubinie, gdzie poznała technikę tańca jazzowego, modern i hip-hop.

    //         Od 2010 do 2014 roku jako instruktor tańca w Studio Tańca Fuego pracowała z dziećmi od 5 roku życia oraz młodzieżą. Nauczała tańca nowoczesnego oraz jazzowego.
            
    //         W 2013 roku ukończyła studia licencjackie na Wyższej Szkole Umiejętności w Kielcach na kierunku: taniec. Studia te były pod patronatem Kieleckiego Teatru Tańca, gdzie studenci szkolili się pod okiem dyrekcji – Elżbiety Szlufik – Pańtak oraz Grzegorza Pańtaka i choreografów KTT oraz innych.
            
    //         W 2016 roku ukończyła z oceną celującą studia magisterskie na Uniwersytecie Muzycznym Fryderyka Chopina w Warszawie na kierunku: choreografia i teoria tańca, gdzie zachwyciła się improwizacją i skupiła się na pracy nad świadomością ruchu, budowaniu choreografii poprzez improwizację, pracy z nią oraz rozwijaniem jej w ciele tancerza. Dzięki temu fascynuje ją ruch undergroundowy, tajemniczy, stopklatkowy, połamany, poszukuje w swoim ciele oraz swoich kursantów oryginalnych kodów ruchowych, czyli iść tam, gdzie ciało jeszcze nie było. W ramach studiów magisterskich stworzyła mini spektakl taneczny pt. „URBEX” wystawiony w Teatrze Narodowym w Warszawie, w którym to choreografia w dużej mierze była budowana poprzez improwizację. Podczas studiów szkoliła się pod okiem wykfalifikowanych choreografów i pedagogów, m.in. Ewy Wycichowskiej, Andrzeja Morawca, Jacka Wazelina, Roberta Bondary.
            
    //         W tym samym roku ukończyła również Akademię Wychowania Fizycznego Józefa Piłsudskiego w Warszawie, gdzie uzyskała uprawnienia trenera personalnego.
            
    //         Mieszkając w Warszawie była tancerką w projektach teatralno – tanecznych prowadzonych prze Kubę Jóźwiaka, zwycięzcę V-tej edycji programu You Can Dance. Zajęcia z technik modern, contemporary, pole dance oraz improwizacji. Spektakle wystawiane m.in. w Teatrze WARSawy.
            
    //         Od marca 2017 rozpoczęła pracę w przedszkolu jako instruktor tańca. Zajęcia z dziećmi od 3 roku życia.
            
    //         Czerwiec 2017 – październik 2017 to okres pracy w Warszawskim klubie fitness, gdzie prowadziła zajęcia z fitnessu na trampolinach (posiadana legitymacja instruktora), stretchingu oraz rusz biodrem – zajęcia taneczne dla dorosłych kobiet.
            
    //         Przez sezon taneczny 2017/2018 instruktor tańca w Studio Tańca Hand To Hand. Zajęcia z dziećmi z technik disco dance, taniec nowoczesny, elementy techniki jazzowej.
            
    //         W maju 2018 roku członek ekipy tworzącej teledysk dla zespołu Sound N Grace pt. „Kolejność”. Stworzyła choreografię w klipie, pracowała również jako jedna z osób nad budową teledysku od podstaw.`,
    //   headerImg: "instruktorzy/DSC_6913-Edit.jpg",
    //   headerDances: ["Formacja Jazzowa", "Jazz"],
    //   image: "instruktorzy/DSC_6913-Edit.jpg"
    // },
    {
      id: 5,
      name: "Grzegorz",
      visibility: 1,
      shortDescription: ` `,
      description: `Swoje pierwsze kroki taneczne stawiał w Tańcu Towarzyskim, jednakże gdy spróbował zmysłowej Bachaty, ta od razu zawładnęła jego sercem oraz stała się nieodłączną częścią jego życia. W tańcu ceni sobie przede wszystkim emocje budowane poprzez relację z partnerką. Nieustannie rozwija swoje umiejętności szkoląc się m.in. u takich mistrzów jak Korke y Judith czy Pablo y Raquel. Ponadto brał udział w formacji tanecznej Korke y Judith Bachata Sensual Project 2017, z którą występował m.in. na takich festiwalach jak Bachata Stars Poland 2017. W swoim tańcu czerpie inspiracje z różnych technik tanecznych, m.in. Kizomby, Tanga czy Salsy, co pozwala mu kreowaś swój własny styl o niepowtarzalnym charakterze. Taniec to dla niego przede wszystkim przyjemność, zabawa i odskocznia od życia codziennego, a swoją pasją nieustannie zaraża osoby go otaczające starając się zaszczepić w nich tanecznego bakcyla.`,
      headerImg: "instruktorzy/DSC_6639-Edit.jpg",
      headerDances: ["Bachata", "Taniec towarzyski"],
      image: "instruktorzy/DSC_6639-Edit.jpg"
    },
    {
      id: 6,
      name: "Magda",
      visibility: 1,
      shortDescription: ` `,
      description: `„Taniec to emocja inspirowana muzyką, obrazowana ruchem ciała.”
 
      Magda to tancerka, instruktorka i choreograf – taniec to największa jej miłość. Magda jest wykwalifikowanym, dyplomowanym instruktorem z doświadczeniem w prowadzeniu zajęć grupowych oraz indywidualnych dla dorosłych i młodzieży.  Jej pasją jest również tworzenie choreografii pierwszego tańca.
       
      Swoją przygodę z tańcem rozpoczęła jako dziecko – w krótkim czasie taniec stał się sensem jej życia. Zaczynała od tańca towarzyskiego, który trenowała przez kilka lat pod okiem wielu wybitnych szkoleniowców. Następnie poznała tance z grupy karaibskich i poświęciła się im bez reszty. Salsa LA, Bachata…a także ich mix – tzw. Caribbean (dawniej Latin) Show zaowocowały licznymi startami na turniejach rangi mistrzostw Polski, Europy i Świata. 
      
      Ponieważ jej zdaniem w tańcu jest pasja i wolność i żaden styl nie powinien ograniczać a jedynie byś środkiem wyrazu – Magda stara się czerpać z wielu technik uczestnicząc w różnorodnych eventach/warsztatach tanecznych i szkoląc się u tancerzy  z całego świata takich jak,  np. Yanis Marshall, Louis Vazques, Karen Forcano i Ricardo Vega, Jasmina i Antonio Berardi,  Magda Liuzza czy  Gatica.   
       
      Do jej największych sukcesów tanecznych należy:
      - Mistrzostwo Świata w Formacji Latin Show, 
      - Mistrzostwo Świata w Mini Formacji Latin Show, 
      - Mistrzostwo i Vice Mistrzostwa Polski  w Mini Formacji Latin Show,
      - II Vice Mistrzostwo Polski w duetach salsa shines, 
      - oraz Vice Mistrzostwo Polski w kategorii Bachata Pary z 2018r. 
       
      Ale to nie sukcesy świadczą o tym jakim jest instruktorem a uśmiech na twarzach kursantów i pozytywna energia z którą wychodzą z sali po zajęciach - to dla niej największa nagroda i motywacja :) 
       `,
      headerImg: "instructors/magda.jpg",
      headerDances: [
        "Latino solo",
        "Salsa LA",
        "Bachata",
        "Taniec towarzyski",
        "Pierwszy taniec"
      ],
      image: "instructors/magda.jpg"
    },
    {
      id: 2,
      name: "Masha",
      visibility: 1,
      shortDescription: ` `,
      description: `Cześć wszystkim, mam na imię Masza. Tańczę już 14 lat i to są najszczęśliwsze lata mojego życia. Nie ma chwili, żebym nie tańczyła lub nie myślała o tańcu. Taniec to dla mnie całe życie, to pasja. Z nim wiążę swoją przyszłość. Zaczynałam tańczyć taniec towarzyski kiedy miałam 5 lat. Tańczyłam długo, brałam udział w wielu mistrzostwach, miałam wiele zwycięstw i porażek. Otrzymałam kandydata w mistrzu sportu i zdecydowałam, ze mi to nie wystarcza. Zawsze chcę czegoś więcej, zawsze patrzę do przodu i widzę tam jeszcze wiele rzeczy, których chciałabym się nauczyć. Zaczęłam tańczyć jazz funk – taki wolny, śmiały i nieprzewidywalny styl. Po nim otworzył się dla mnie nowy niesamowity świat „street dance”. Zaczęłam tańczyć dancehall, twerk, hip hop, contemporary, waacking… Brałam udział w wielu zawodach i battlach za granicą, takich jak „Feel the beat”, „Dance monsters”, „Hip hop international”, czy „Dance the floor”. Jeździłam na nieskończoną ilość warsztatów do najsłynniejszych choreografów, takich jak Yanis Marshall, Mariya Kozlowa, Paris Goebel, Alice Docenko, Cameron Lee, David Moore i wielu innych. Potem zdecydowałam, że już mogę podzielić się swoim doświadczeniem z innymi i zaczęłam układać własne choreografie. Teraz pracuję jako trener taneczny. Moi uczniowie inspirują mnie do układania nowych kawałków i mam nieopisaną przyjemność z robienia tego. Ale nie, nie zapominam i o sobie – codziennie trenuję, rozwijam się i próbuję czegoś nowego. Taniec wywołuje u mnie poczucie spełnienia i wolności. Mogę się stać każdą postacią, jaką w danej chwili potrzebuję. Taniec to moja obsesja, mój powód do uśmiechu, moja miłość.`,
      headerImg: "instruktorzy/DSC_6755-Edit.jpg",
      headerDances: ["Sexy dance", "Taniec towarzyski", "Twerk"],
      image: "instruktorzy/DSC_6755-Edit.jpg"
    },
    // {
    //   id: 8,
    //   name: "Amanda",
    //   visibility: 1,
    //   shortDescription: ` `,
    //   description: `Pierwsze kroki w tańcu zaczęła stawiać w wieku 4 lat. 
    //   Na początku był to balet. Po kilku latach zainteresowała rytmam streetowymi,co zaawocowało nauką hip-hopu,
    //   by jednak dość szybko wrócić do klasyki - Tańca Towarzyskiego.
    //   Kilka lat temu poznała Salsę, która całkowcie ją zafascynowała.
    //   Od tego czasu skupiła się na rozwoju w gatnkach Afrokubańskich takich jak Salsa Cubana (Casino),
    //   Timba, Reggaeton, Afro oraz Rumba Kubańska. `,
    //   headerImg: "instructors/DSC02372.png",
    //   headerDances: [""],
    //   image: "instructors/DSC02372.png"
    // },
    {
      id: 3,
      name: "Dorota i Marcin",
      visibility: 1,
      shortDescription: ` `,
      description: `Razem tańczą od 2018r. Zaangażowani w projekt Bachata Guinness Challenge, gdzie weryfikowali i przygotowywali pary do pobicia rekordu, czym dołożyli swoją cegiełkę do sukcesu sympatyków tego tańca. Współpracowali przy International Bachata FlashMob we Wrocławiu, do ktorego uczyli choreografii. Razem występowali w formacjach "WOW Bachata" oraz "Freakchata" - grup utworzonych przez wrocławskich instruktorów.

      Dorota: Przygodę z tańcem zaczęła od salsy liniowej. Jednak w 2017 na tanecznym evencie trafiła na zajęcia z bachaty i to ona skradła jej serce, szczególnie sensual i jego zmysłowość. Naukę kontynuowała we Wroclawiu oraz na wielu polskich i zagranicznych festiwalach, dzięki czemu mogła uczyć się od najlepszych.
      Dołączyła do kilku projektów i formacji tanecznych, by móc nie tylko być uczestnikiem eventów, ale także na nich występować. Do bachaty wplata elementy z innych styli - głównie zouka, ktory nadaje jej tańcu płynności. Na zajęciach kładzie duży nacisk na technikę i prace ciałem.
      
      Marcin: Zderzenie z tańcem nastąpiło tuż po przeprowadzce do Wrocławia w 2017r. Naukę rozpoczął od tańca użytkowego, lecz po niedługim czasie zakochał się w energicznej bachacie. Pod okiem wielu utalentowanych tancerzy z kraju i z zagranicy, spełnił marzenie o zostaniu instruktorem. Marcin szczególnie upodobał sobie łączenie drobnych smaczków i energii z innych styli tanecznych, takich jak zouk, urban kiz, czy szeroko pojęty street dance. Współtwórca i uczestnik grup choreograficznych oraz inicjatyw związanych ze światem tańca. Ma na swoim koncie liczne występy w kraju, nie tylko jako tancerz, ale również DJ. Jego zajęcia mają bardzo rozrywkowy charakter, z dużą dawką dystansu do siebie, aby w pełni pokazać własną wizję bachaty - pełnej radości i zabawy. `,
      headerImg: "instructors/dandm.JPG",
      headerDances: [""],
      image: "instructors/dandm.JPG"
    },
    {
      id: 4,
      name: "Nina",
      visibility: 1,
      shortDescription: ` `,
      description: `Na imię mam Nina. Zaczęłam tańczyć jako mała dziewczynka i myślę, że pasja do tańca dorastała we mnie ze mną J Dziś, jako dorosła kobieta taniec traktuje jako dotychczasową największą zajawkę, która dostarcza mi wielu emocji. W tańcu, a szczególnie w hip hopie najbardziej lubię tę wolność duszy oraz poczucie prawdziwego bycia sobą, do czego gorąco zachęcam, poprzez swoją pasję. `,
      headerImg: "instructors/nina.jpg",
      headerDances: [""],
      image: "instructors/nina.jpg"
    }
  ]
};

const instructorsReducer = (state = initialState, action) => {
  return state;
};

export default instructorsReducer;
