const initialState = {
  navigationItems: [
    {
      text: "Strona główna",
      path: "/strona-glowna",
      toggleable: false,
      isChild: false
    },
    {
      text: "Oferta dodatkowa",
      toggleable: true,
      isChild: false
    },
    {
      text: "Pierwszy taniec",
      path: "/oferta-dodatkowa/pierwszy-taniec",
      toggleable: false,
      isChild: true
    },
    {
      text: "Wieczory panieńskie",
      path: "/oferta-dodatkowa/wieczory-panienskie",
      toggleable: false,
      isChild: true
    },
    {
      text: "Pokazy tańca",
      path: "/oferta-dodatkowa/pokazy-tanca",
      toggleable: false,
      isChild: true
    },
    {
      text: "Zajęcia indywidualne / PRO-AM",
      path: "/oferta-dodatkowa/zajecia-indywidualne",
      toggleable: false,
      isChild: true
    },
    {
      text: "Grafik",
      path: "/grafik",
      toggleable: false,
      isChild: false
    },
    {
      text: "Cennik",
      path: "/cennik",
      toggleable: false,
      isChild: false
    },
    {
      text: "Kontakt",
      path: "/kontakt",
      toggleable: false,
      isChild: false
    }
  ]
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default navigationReducer;
