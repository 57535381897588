import React, { Component } from "react";
import HeaderImage from "./HeaderImage/HeaderImage";
import HeaderDescription from "./HeaderDescription/HeaderDescription";
import HeaderDances from "./HeaderDances/HeaderDances";

import * as styles from "./Instructors.module.css";
import { connect } from "react-redux";

class Instructors extends Component {
  switchInstructorLeft = currentInstructor => {
    const currentItemId = currentInstructor.id;
    const maximumInstructorIndex = this.props.instructors.length;
    const minimumInstructorIndex = 1;

    let newItemId = currentItemId - 1;

    if (newItemId < minimumInstructorIndex) {
      newItemId = maximumInstructorIndex;
    }

    const newInstructor = this.props.instructors.find(d => d.id === newItemId);

    this.props.history.push(`/instruktor/${newInstructor.id}`);
  };

  switchInstructorRight = currentInstructor => {
    const currentItemId = currentInstructor.id;
    const maximumInstructorIndex = this.props.instructors.length;
    const minimumInstructorIndex = 1;

    let newItemId = currentItemId + 1;

    if (newItemId > maximumInstructorIndex) {
      newItemId = minimumInstructorIndex;
    }

    const newInstructor = this.props.instructors.find(d => d.id === newItemId);

    this.props.history.push(`/instruktor/${newInstructor.id}`);
  };

  render() {
    const currentInstructor = this.props.instructors.find(
      instructor => +instructor.id === +this.props.match.params.id
    );

    if (!currentInstructor) {
      this.props.history.replace("/nie-znaleziono");
      return <div />;
    }

    return (
      <div className={styles.Instructors}>
        <div className={styles.headerName}>
          <p>{currentInstructor.name}</p>
        </div>
        <HeaderDescription description={currentInstructor.description} />
        <HeaderImage
          image={currentInstructor.headerImg}
          switchInstructorLeft={() =>
            this.switchInstructorLeft(currentInstructor)
          }
          switchInstructorRight={() =>
            this.switchInstructorRight(currentInstructor)
          }
        />
        <HeaderDances dances={currentInstructor.headerDances} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    instructors: state.instructorsReducer.instructors
  };
};

export default connect(mapStateToProps)(Instructors);
