import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";
import HomePage from "./components/HomePage/HomePage";

import FirstDance from "./components/AdditionalOffer/FirstDance/FirstDance";
import BatchParty from "./components/AdditionalOffer/BatchParty/BatchParty";
import DanceShows from "./components/AdditionalOffer/DanceShows/DanceShows";
import IndividualClasses from "./components/AdditionalOffer/IndividualClasses/IndividualClasses";

import Contact from "./components/Contact/Contact";
import Dances from "./components/Dances/Dances";
import Instructors from "./components/Instructors/Instructors";
import Prices from "./components/Prices/Prices";
import Schedule from "./components/Schedule/Schedule";
import NotFound from "./components/NotFound/NotFound";

import ScrollToTop from "react-router-scroll-top";
import WeddingDance from "./components/WeddingDance/WeddingDance";

class App extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/strona-glowna/" component={HomePage} />
          <Route
            exact
            path="/oferta-dodatkowa/pierwszy-taniec"
            component={FirstDance}
          />
          <Route
            exact
            path="/oferta-dodatkowa/wieczory-panienskie"
            component={BatchParty}
          />
          <Route
            exact
            path="/oferta-dodatkowa/pokazy-tanca"
            component={DanceShows}
          />
          <Route
            exact
            path="/oferta-dodatkowa/zajecia-indywidualne"
            component={IndividualClasses}
          />
          {/* <Route path="/pierwszy-taniec" component={WeddingDance} /> */}
          <Route path="/grafik" component={Schedule} />
          <Route path="/cennik" component={Prices} />
          <Route path="/kontakt" component={Contact} />
          <Route exact path="/taniec/:name" component={Dances} />
          <Route exact path="/instruktor/:id" component={Instructors} />
          <Route path="/nie-znaleziono" component={NotFound} />
          <Redirect exact from="/" to="/strona-glowna" />
          <Redirect from="/" to="/nie-znaleziono" />
        </Switch>

        <ScrollToTop />
      </Layout>
    );
  }
}

export default App;
