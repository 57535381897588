const initialState = {
  sideBarItems: ["home", "about", "dances", "instructors", "reviews", "video"],
  reviews: [
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Fug totam, itaque officia ex.",
      name: "Dawid",
      date: "01/10/2018",
      rating: "10/10"
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adia doloremque architecto dicta animi, totam, itaque officia ex.",
      name: "Weronika",
      date: "11/10/2018",
      rating: "8/10"
    },
    {
      text: "Lorem ipsum dolor sit ame.",
      name: "Magda",
      date: "13/10/2018",
      rating: "10/10"
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Fugae architecto dicta animi, totam, itaque officia ex.",
      name: "Zofia",
      date: "14/10/2018",
      rating: "8/10"
    },
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit.Fuga doloremque architecto dicta animi, totam, itaque officia ex.",
      name: "Radek",
      date: "21/10/2018",
      rating: "9/10"
    }
  ],
  headerSliderImages: [
    { text: "homepage4.jpg" },
    { text: "homepage7.jpg" },
    { text: "instruktorzy/DSC_6804-Edit.jpg" },
    { text: "dances/kids.jpg" },
    { text: "homepage6.jpg" },
    { text: "girls/DSC_6873-Edit.jpg"},
    { text: "dances/27.JPG"}
    ]
};

const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};


export default homePageReducer;
