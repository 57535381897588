import React from "react";

import * as styles from "./CardMobile.module.css";

const CardMobile = props => {
  const combinedClass = `${props.color} ${styles.CardMobile}`;

  let filledCardMobile = (
    <div className={combinedClass}>
      <div className={styles.content}>
        <div
          className={[styles.link, styles.danceName].join(" ")}
          onClick={() => props.navigateToDance(props.link)}
        >
          <p>{props.courseName}</p>
        </div>
        <div>
          <p>{props.time}</p>
        </div>

        {props.instructor.name !== undefined ? (
          <div
            className={styles.link}
            onClick={() => props.navigateToInstructor(props.instructor.id)}
          >
            <p>{props.instructor.name}</p>
          </div>
        ) : (
          <div className={styles.link}>
            <p>{props.instructor}</p>
          </div>
        )}

        <div>
          <p>{props.level}</p>
        </div>
      </div>

      {props.info.status !== "CLOSED" ? (
        <a
          href="#form"
          onClick={() => {
            props.shouldPartnerNameBeVisible(props.isDanceInPairs);
            props.openPopup();
            props.setCurrentFormData(props.courseName, props.time, props.day);
          }}
          className={[styles.btn, styles.link].join(" ")}
        >
          {props.info.buttonText}
        </a>
      ) : (
        <a href="_" className={[styles.btn, styles.link].join(" ")}>Brak miejsc</a>
      )}

      <div className={styles.informations}>
        <div>{props.info.text}</div>
      </div>
    </div>
  );
  return filledCardMobile;
};

export default CardMobile;
