import React, { Component } from "react";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navigation/Navigation";
import { connect } from "react-redux";
import DesktopNavigation from "../../components/DesktopNavigation/DesktopNavigation";

class Layout extends Component {
  render() {
    return (
      <>
        <DesktopNavigation></DesktopNavigation>
        <Navigation items={this.props.navigationItems} />
        <main>{this.props.children}</main>
        <Footer imageUrl="danceflow-white-1.png" />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    navigationItems: state.navigationReducer.navigationItems
  };
};

export default connect(mapStateToProps)(Layout);
