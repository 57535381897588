import React, { Component } from "react";
import Form from "../../../ReusableComponents/Form/Form";
import * as styles from "./Popup.module.css";

class Popup extends Component {
  render() {
    let popup = null;

    if (this.props.toggled) {
      popup = (
        <div data-aos="fade-in" className={styles.popup} id="popup">
          <div data-aos="zoom-in-up" className={styles.popupContent}>
            <div className={styles.background}>
              <div className={styles.form}>
                <Form
                  isDanceInPairs={this.props.isDanceInPairs}
                  danceName={this.props.danceName}
                  time={this.props.time}
                  day={this.props.day}
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => this.props.close()}
            className={styles.popupClose}
          >
            &times;
          </button>
        </div>
      );
    }

    return popup;
  }
}

export default Popup;
