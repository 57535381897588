import React, { Component } from "react";
import Header from "./Header/Header";
import About from "./About/About";
import Dances from "./Dances/Dances";
import Instructors from "./Instructors/Instructors";

import SimpleSlider from "./SimpleSlider/SimpleSlider";
import { connect } from "react-redux";
import * as styles from "./HomePage.module.css";

class HomePage extends Component {

  render() {
    return (
      <section className={styles.HomePage}>
        <Header
          headerSliderImages={this.props.headerSlider}
          headerMainText="dance"
          headerLowerText="flow"
          catchPhrase1="profesjonalna"
          catchPhrase2="szkoła tańca"
          imageUrl="danceflowmain.png"
          headerLogoBlackWhite="logo_danceflow.png"
        />

        <About />

        <Dances dances={this.props.dances} />

        <Instructors>
          <SimpleSlider instructors={this.props.instructors} />
        </Instructors>

        <div className={styles.mediaContainer}>
          <iframe
          title="yt1"
            width="452"
            height="315"
            className={styles.iframeMedia}
            src="https://www.youtube.com/embed/wT2QecqEL2w"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <iframe
          title="yt2"
            width="452"
            height="315"
            className={styles.iframeMedia}
            src="https://www.youtube.com/embed/RKUhWbfShjA"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <iframe
          title="yt3"
            width="452"
            height="315"
            className={styles.iframeMedia}
            src="https://www.youtube.com/embed/gvLTewKoU1s"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    sideBarItems: state.homePageReducer.sideBarItems,
    reviews: state.homePageReducer.reviews,
    instructors: state.instructorsReducer.instructors,
    dances: state.dancesReducer.dances,
    headerSlider: state.homePageReducer.headerSliderImages
  };
};

export default connect(mapStateToProps)(HomePage);
