import React from "react";
import { NavLink } from "react-router-dom";
import * as styles from "../Navigation.module.css";

const NavigationItem = props => {
  return (
    <>
      <li className={styles.item} onClick={() => props.clicked()}>
        <NavLink
          exact
          to={props.path}
          className={styles.link}
          activeClassName={styles.active}
        >
          {props.text}
        </NavLink>
      </li>
    </>
  );
};

export default NavigationItem;
