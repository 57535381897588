const HEADER_LOGO_BLACK = "danceflow-white-black-1.png";

const initialState = {
  firstDances: [
    {
      name: "pierwszy-taniec",
      headerText: "pierwszy",
      headerLowerText: "taniec",
      headerLogoPath: HEADER_LOGO_BLACK,
      images: [
        "fdance.jpg",
        "fdance2.jpg",
        "fdance3.jpg",
        "dominika_i_tomek.jpg",
        "dominika_i_tomek2.jpg",
        "agata_i_mateusz.jpg",
        "agata_i_mateusz2.jpg",
        "pamelapawel1.jpg",
        "pamelapawel2.jpg",
        "pierwszytaniec1.jpg",
        "pierwszytaniec2.jpg",
        "pierwszytaniec3.jpg"
      ],
      sectionName: "Pierwszy taniec",
      text: [`
      Pierwszy taniec z Klarą to strzał w dziesiątkę! Mieliśmy wygórowane oczekiwania, 
      konkretną piosenkę i styl taneczny. Taniec nie miał był piękny - miał powalić na 
      kolana. I to się w 100% udało! Klara jest profesjonalistką - pełną radości, cierpliwości i pomysłów!
       Zwracała uwagę na ważne detale, które amatorom nie
       rzucają się w oczy, ale np. na zdjęciach widać je od razu. 
       Gorąco polecamy - zarówno tym mniej, jak i bardziej tańczącym `,

        `Polecamy z całego serca zajęcia z Magdą- stworzyła dopasowaną do nas i do wybranej przez nas piosenki, piękną choreografię. 
        Dzięki jej podpowiedziom oraz atmosferze na spotkaniach nauka tańca była dla nas przyjemnością.  
        W krótkim czasie pomogła opanować nam układ oraz dopracować go tak, że zatańczyliśmy go bezbłędnie, lekko i bez jakiegokolwiek stresu!`,

        `Klara była dla nas niezastąpionym wsparciem w gorączce 
            ostatnich ślubnych przygotowań. 
            Ułożyła pod naszą wybraną piosenkę bardzo efektowny układ, 
            a dzięki trikom, jakie nam zdradziła, kilka wypracowanych 
            prostych ruchów dawało wrażenie wymyślnej figury tanecznej. 
            Najbardziej jednak jesteśmy jej wdzięczni za niewzruszony wiare w nasze możliwości, 
            dużo śmiechu i świetna zabawę, dzięki której mogliśmy zrelaksować się w
             tym uroczym, lecz zabieganym czasie… Polecamy z całego serca 🙂`,

        `Polecamy zajęcia u Klary ze względu na luźną atmosferę, ale fachowe podejście. Dzięki niej nasz pierwszy taniec był wyjątkowy. Warto jest najlepsza !!!`,

        `Pierwszy taniec w Naszym przypadku nie był łatwa sprawą.
            Mąż lubi muzykę i ma poczucie rytmu ale nie umie i nie lubi tańczyć. Okazjonalnie przymuszany gibał się z nogi na nogę do tego nie znosi publicznych wystąpień. Ja uwielbiająca tańce, pokazy, marząc o wirowaniu w ramionach ukochanego ambitnie podeszłam​ do sprawy. No i Nasza piosenka! Nie jakieś tup tup na dwa tylko sam Pan Sting i rumba.
            
            Jak to pogodzić?
            Otóż Klarze się udało.
            
            Mąż niczym kawaler z balu debiutantów dostojnie prowadził taniec tak, że ja nie musiałam 🙂 plątał mnie w zmyślnie wyglądające figury, które nie były aż tak trudne do wykonania jak na to wyglądały i to wszystko przy Naszej piosence.
            Lekcje Klary zlatywały szybko bo w swobodnej i humorystyczne atmosferze, bez stresu z dużą ilością śmiechu, dziewczyna ma bardzo pedagogiczne podejście. Mąż szybko się rozluźnił i pochłaniał kolejne kroki a ja przeszczęśliwa że Nasz Pierwszy Taniec wyszedł taki jakim go sobie wymarzyłam. Do tego na innych imprezach staliśmy się królami parkietu – prawie :P.
            Gorąco polecam Klarę, z drewniaka potrafi zrobić Maseraka`,

        `Polecamy szkołę tańca Klary. 
            Jeśli chcecie przygotować się do pierwszego tańca z profesjonalistką , 
            osobą w pełni oddaną swojej pasji, zaangażowanej oraz kreatywnej,a przy tym elastycznej pod względem  
            ustalenia odpowiednich godzin jak również miejsc nauki to z pewnością instruktor dla Was 🙂  
            Służy swoim doświadczeniem, potrafi wydobyć potencjał drzemiący w każdym z nas. Pogodna , sympatyczna  
            i komunikatywna osoba, która w każdym aspekcie przygotowania 1 Tańca jest w stanie Wam pomóc…od wsparcia w 
            wyborze rodzaju tańca w zależności od sukni po przygotowanie figur ,  którymi możecie zachwycić waszych Gości.  
            Sami się o tym przekonajcie`
      ]
    }
  ]
};
const firstDanceReducer = (state = initialState, action) => {
  return state;
};

export default firstDanceReducer;
