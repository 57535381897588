import React, { Component } from "react";
import Header from "./Header/Header";
import { connect } from "react-redux";
import * as styles from "./Dances.module.css";

class Dances extends Component {
  removePolishLetters = name => {
    return name
      .trim()
      .replace(" ", "-")
      .replace("ą", "a")
      .replace("ę", "e")
      .replace("ć", "c")
      .replace("ł", "l")
      .replace("ń", "n")
      .replace("ó", "o")
      .replace("ś", "s")
      .replace("ż", "z")
      .replace("ź", "z");
  };

  switchDanceLeft = (currentDance, event$) => {
    console.log(event$);
    event$.preventDefault();
    const currentItemId = this.props.dances.indexOf(currentDance);
    const maximumDancesIndex = this.props.dances.length - 1;
    const minimumDancesIndex = 0;

    let newItemId = currentItemId - 1;

    if (newItemId < minimumDancesIndex) {
      newItemId = maximumDancesIndex;
    }

    const newDance = this.props.dances[newItemId];

    console.log("Dances: ",this.props.dances);
    console.log(newDance);

    this.props.history.push(
      `/taniec/${this.removePolishLetters(newDance.name)}`
    );
  };

  switchDanceRight = (currentDance, event$) => {
    console.log(event$);
    event$.preventDefault();
    const currentItemId = this.props.dances.indexOf(currentDance);
    const maximumDancesIndex = this.props.dances.length - 1;
    const minimumDancesIndex = 0;

    let newItemId = currentItemId + 1;
    console.log("newItemId: ", newItemId);
    if (newItemId > maximumDancesIndex) {
      newItemId = minimumDancesIndex;
    }

    const newDance = this.props.dances[newItemId];

    console.log("Dances: ",this.props.dances);
    console.log(newDance);

    this.props.history.push(
      `/taniec/${this.removePolishLetters(newDance.name)}`
    );
  };

  render() {
    const currentDance = this.props.dances.find(
      dance =>
        this.removePolishLetters(dance.name) ===
        this.props.match.params.name.trim().replace(" ", "-")
    );

    if (!currentDance) {
      this.props.history.replace("/nie-znaleziono");
      return <div />;
    }

    let nearestClasses = this.props.scheduleItems
      .filter(item => item.danceId === currentDance.id)
      .map((item, index) => {
        return (
          <div className={styles.classes} key={index}>
            <p>{item.day}</p>
            <p>Godzina: {item.time}</p>
            <p>Poziom: {item.level}</p>
          </div>
        );
      });

    if (nearestClasses === undefined || nearestClasses.length === 0) {
      nearestClasses = (
        <div>Aktualnie nie prowadzone są zajęcia z tego tańca.</div>
      );
    }

    return (
      <div className={styles.Dances}>
        <Header
          name={currentDance.name}
          image={currentDance.headerImage}
          description={currentDance.content}
          currentDance={currentDance}
          switchDanceLeft={(event$) => this.switchDanceLeft(currentDance, event$)}
          switchDanceRight={(event$) => this.switchDanceRight(currentDance, event$)}
        />
        <div className={styles.mainSection}>
          <div className={styles.video}>
            <iframe
              title="video for dance"
              width="100%"
              height="500"
              src={currentDance.video}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div className={styles.content}>
            <div className={styles.nearestClasses}>
              <p className={styles.contentHeader}>Najbliższe zajęcia:</p>
              <div className={styles.schedule}>{nearestClasses}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dances: state.dancesReducer.dances.filter(x => x.isActive === true).sort((a,b) => a.id - b.id),
    scheduleItems: state.scheduleReducer.scheduleItems
  };
};

export default connect(mapStateToProps)(Dances);
