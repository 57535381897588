import React from "react";
import * as styles from "./Instructors.module.css";

const Instructors = props => {
  return (
    <div className={styles.section}>
      <div className={styles.text}>Instruktorzy</div>
      <div className={styles.instructorSlider}>{props.children}</div>
      <div className={styles.background}>&nbsp;</div>
    </div>
  );
};

export default Instructors;
