import React, { Component } from "react";
import Header from "./Header/Header";
import Information from "./Information/Information";
import {connect} from "react-redux";

class FirstDance extends Component {
 
  render() {
    let dance = this.props.firstDances[0];
    return (
      <div>
        <Header
          headerMainText={dance.headerText}
          headerLowerText={dance.headerLowerText}
          headerLogoBlackWhite={dance.headerLogoPath}
        />
        <Information
            sectionName={dance.sectionName}
            mainText={dance.text}
            images={dance.images}
          />
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      firstDances: state.firstDanceReducer.firstDances
    };
  };

export default connect(mapStateToProps)(FirstDance);
