import React from "react";
import * as styles from "./NotFound.module.css";

const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <div className={styles.container}>
        <h1>Strona o podanym adresie nie istnieje :( error 404</h1>
      </div>
    </div>
  );
};

export default NotFound;
