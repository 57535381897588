import React from "react";
import * as styles from "./Card.module.css";

const Card = props => {
  return (
    <div className={styles.Card}>
      <div className={styles.background}>&nbsp;</div>
      <div className={styles.cardContent}>
        <p>{props.name}</p>
        <h2>{props.price}</h2>
        <h6>{props.description}</h6>
        <p>{props.singlePrice}</p>
      </div>
    </div>
  );
};

export default Card;
