import React from "react";
import * as styles from "./About.module.css";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className={styles.About}>
      <div className={styles.containerLeft}>
        <div className={styles.title}>
          <h2 data-aos="slide-right" data-aos-duration="600">
            Dance Flow – Szkoła Tańca we Wrocławiu
          </h2>
          <p data-aos="fade-up" data-aos-duration="600">
            Szkoła Tańca Dance Flow to moje wielkie marzenie. Taniec wypełnia
            moje życie od kiedy tylko pamiętam, miał różne oblicza i zmieniał
            się przez lata, pokazywał mi różne twarze i pozwalał odkrywać nowe
            uczucia i emocje. Stał się moja wielką pasją. Z czasem okazało się,
            że uczenie innych przekazywanie tej wielkiej pasji, rozwijanie
            talentu daje mi wielką satysfakcję i radość. Mam przyjemność
            prowadzić zajęcia i uczyć od 2008 roku i zbierałam się bardzo długo
            z własnym miejscem gdzie będę mogła nie tylko pracować ale dzielić
            się miłością do tańca i odkrywać nowe talenty, wasze talenty.
            Nazywam się{" "}
            <span className={styles.highlighted}>
              <Link exact="true" to={"/instruktor/1"}>
                Klara Kupińska
              </Link>
            </span>{" "}
            i z wielka przyjemnością zapraszam was do mojej Szkoły Tańca!
          </p>
        </div>
      </div>
      <div className={styles.containerRight}>
        <img
          width="10rem"
          height="auto"
          className={styles.image1}
          src={require(`../../../assets/images/acd.jpg`)}
          alt="about_image"
        />
        <img
          width="10rem"
          height="auto"
          className={styles.image2}
          src={require(`../../../assets/images/abc2_mobile.jpg`)}
          alt="about_image2"
        />
      </div>
    </div>
  );
};

export default About;
