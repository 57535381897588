import React, { Component } from "react";
import { connect } from "react-redux";
import * as styles from "./WeddingDance.module.css";

class WeddingDance extends Component {
  render() {
    return (
      <div className={styles.WeddingDance}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <img alt="flower" className={styles.headerFlower} src={require("../../assets/images/weddingdance/watercolor-cotton-flower.png")}/>
            <img alt="flower2" className={styles.headerFlowerTwo} src={require("../../assets/images/weddingdance/watercolor-cotton-flower.png")}/>
            <h1 className={styles.headerText}>Pierwszy</h1> 
            <h1 className="headerText, headerTextTwo">Taniec</h1>
            <p className={styles.headerTextParagraph}>Z DANCEFLOW</p>
            <div className={styles.headerSecondaryText}>
              <p className={styles.headerSecondaryTextParagraph}>
                Planujesz Ślub i Wesele, wszystko ma być piękne i wyjątkowe ale
                stresuje Cię myśl o pierwszym tańcu?
              </p>
              <p className={styles.headerSecondaryTextParagraph}>
                {`Idealnie trafiłeś - Szkoła Tańca Dance Flow ma kilku genialnych
              instruktorów zajmujących się nauką Pierwszego Tańca!`}
              </p>
              <div className={styles.buttonContainer}>
                <button className={styles.weddingButton}>
                  <i class="material-icons">event_available</i>
                  <p className={styles.weddingButtonText}>Zapisz się już dziś!</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(WeddingDance);
