export const BACHATA_LEJDIS = "bachata_lejdis";
export const BURLESKA = "burleska";
export const FLAMENCO = "flamenco";
export const JAZZ = "jazz";
export const POWER_STRETCHING = "power_stretching";
export const SEXY_DANCE = "sexy_dance";
export const TANIEC_BRZUCHA = "taniec_brzucha";
export const TWERK = "twerk";
export const LATINO_SOLO = "latino_solo";
export const HIP_HOP = "hip_hop";
export const DANCEHALL = "dancehall";
export const MUSICAL_DANCE = "musical_dance";
export const BODY_BALLET = "body_ballet";
export const HIGH_HEELS = "high_heels";
export const SALSA = "salsa";
export const BACHATA = "bachata";
export const DISCOFOX = "discofox";
export const ZOUK = "zouk";
export const SALSA_CUBANA = "salsa_cubana";
export const TANIEC_TOWARZYSKI = "taniec_towarzyski";
export const BALET = "balet";
export const FORMACJA_JAZZOWA = "formacja_jazzowa";
export const FLOW_GIRLS = "flow_girls";
export const POWER_FITNESS = "power_fitness";
export const TANCE_SWINGOWE = "tance_swingowe";
export const SALSA_LA = "salsa_la";

export const IN_PAIRS = "inPairs";
export const SOLO = "solo";
export const DANCE_FORMATION = "danceFormation";
export const KIDS = "kids";
