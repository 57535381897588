import React, { Component } from "react";
import Header from "./Header/Header";
import * as styles from "./IndividualClasses.module.css";
import { connect } from "react-redux";

class IndividualClasses extends Component {
  render() {
    let dance = this.props.individualDances[0];
    return (
      <div>
        <Header
          headerMainText={dance.headerText}
          headerLowerText={dance.headerLowerText}
          headerLogoBlackWhite={dance.headerLogoPath}
        />
        <div className={styles.mainSection}>
          <p>
            Potrzebujesz dodatkowych zajęć bo chcesz poprawić umiejętności
            taneczne lub nauczyć się wybranego stylu tańca od początku?
          </p>
          <p>
            A może tańczysz od lat i pragniesz rozwijać się na jeszcze wyższym
            poziomie?
          </p>
          <p>
            Świetnie trafiłeś w naszej ofercie mamy zajęcia 
            <span className={styles.highlighted}> PRO - AM</span> czyli
            <span className={styles.highlighted}> Profesjonalista - Amator</span>
          </p>
          <p>
            Wybieramy albo wybierasz najlepszego instruktora w stylu tanecznym
            który chcesz rozwijać i zaczynamy pracę indywidualną w terminach
            które Tobie odpowiadają.
          </p>
          <p>Zadzwoń i zapytaj tel. <span className={styles.highlighted}>783952086</span></p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    individualDances: state.individualDancesReducer.individualDances
  };
};

export default connect(mapStateToProps)(IndividualClasses);
