import React, { Component } from "react";
import * as styles from "./Navigation.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import SubNavigation from "./SubNavigation/SubNavigation";
import { connect } from "react-redux";

class Navigation extends Component {
  checkboxRef = React.createRef();

  toggleNavigation = () => {
    this.checkboxRef.current.checked = !this.checkboxRef.current.checked;
  };

  render() {
    const subItems = this.props.items.filter(
      navItem => navItem.isChild === true && navItem.toggleable === false
    );

    return (
      <div className={styles.Navigation}>
        <input
          type="checkbox"
          className={styles.checkbox}
          id="navi-toggle"
          ref={this.checkboxRef}
        />
        <label htmlFor="navi-toggle" className={styles.label}>
          <span className={styles.icon}>&nbsp;</span>
        </label>
        <div className={styles.background}>
          &nbsp;
          <div className={styles.logo}>
            <img
              width="90"
              src={require("../../assets/images/danceflow-white-1.png")}
              alt="logodanceflow"
            />
          </div>
        </div>
        <div className={styles.container}>
          <ul className={styles.list}>
            {this.props.items.map((item, index) =>
              item.toggleable ? (
                <SubNavigation
                  items={subItems}
                  key={index}
                  clicked={this.toggleNavigation}
                />
              ) : item.isChild === false ? (
                <NavigationItem
                  text={item.text}
                  path={item.path}
                  key={index}
                  clicked={this.toggleNavigation}
                />
              ) : null
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    navigationItems: state.navigationReducer.navigationItems
  };
};

export default connect(mapStateToProps)(Navigation);
