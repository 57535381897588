import React, { Component } from "react";
import * as styles from "./Header.module.css";

class Header extends Component {
  render() {
    const image = this.props.currentDance.image;
    let contentDescription =
      this.props.description.acapits !== null &&
      this.props.description.acapits !== "" &&
      this.props.description.acapits !== undefined &&
      this.props.description.middle !== "" &&
      this.props.description.middle !== null &&
      this.props.description.middle !== undefined ? (
        <div>
          <div>{this.props.description.middle}</div>
          <div>
            <br />
            <b>
              <p>{this.props.description.acapits}</p>
              <br />
            </b>
          </div>
          <div>{this.props.description.end}</div>
        </div>
      ) : (
        <div>{this.props.description.end}</div>
      );

    return (
      <div className={styles.Header}>
        <div className={styles.mainText}>
          <h1> {this.props.currentDance.name}</h1>
        </div>
        <div className={styles.containerLeft}>
          <div className={styles.navi}>
            <div
              onClick={this.props.switchDanceLeft}
              className={styles.arrowLeft}
            >
              {" "}
              &lt;{" "}
            </div>
            <div
              onClick={this.props.switchDanceRight}
              className={styles.arrowRight}
            >
              {" "}
              &gt;{" "}
            </div>
          </div>
        </div>
        <div
          className={styles.containerMiddle}
          style={{
            backgroundImage:
              `url(` + require("../../../assets/images/" + image) + `)`
          }}
        >
          &nbsp;
        </div>
        <div className={styles.containerRight}>
          <div className={styles.description}>{contentDescription}</div>
        </div>
      </div>
    );
  }
}

export default Header;
