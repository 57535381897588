import React, { Component } from "react";
import * as styles from "./Form.module.css";
import { API_URL } from "../../../consts/mailApiConfig";
import axios from "axios";

class Form extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    partnersName: "",
    danceName: "",
    day: "",
    hour: "",
    submitted: false,
    error: false,
    subject: "Zapisy na zajęcia:",
    disabled: false,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      danceName: nextProps.danceName ? nextProps.danceName : "",
      day: nextProps.day ? nextProps.day : "",
      hour: nextProps.time ? nextProps.time : "",
    });
  }

  handleUserInput(e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  submitForm = (e) => {
    e.preventDefault();

    if (
      this.state.name &&
      this.state.email &&
      this.state.phone &&
      this.state.danceName &&
      this.state.day &&
      this.state.hour &&
      this.state.disabled === false
    ) {
      this.setState({ disabled: true });

      const payload = {
        subject:
          this.state.subject +
          " " +
          this.state.danceName +
          ", " +
          this.state.day +
          ", " +
          this.state.hour,
        to: "kontakt@danceflow.pl",
        from: this.state.email,
        messageContent: `Imię: ${this.state.name}\n 
        ${this.state.partnersName &&
          "Imię partnera/partnerki: " + this.state.partnersName + "\n"}
        Email: ${this.state.email}\n
        Numer telefonu: ${this.state.phone}\n
        Zajęcia: ${this.state.danceName}\n
        Dzień: ${this.state.day}\n
        Godzina: ${this.state.hour}`,
      };

      console.log(payload);
      this.postData(API_URL, payload);
    }
  };

  postData = (url = ``, data = {}) => {
    axios
      .post(url, data, { crossdomain: true })
      .then((data) =>
        this.setState({ submitted: true, error: false, disabled: false })
      )
      .catch((error) => {
        this.setState({ submitted: false, error: true, disabled: false });
      });
  };

  resubmitForm = (e) => {
    this.setState({
      name: "",
      phone: "",
      email: "",
      partnersName: "",
      danceName: "",
      day: "",
      hour: "",
      submitted: false,
      error: false,
    });
  };

  render() {
    let formContent = (
      <div className={styles.book}>
        <div className={styles.bookForm}>
          <form action="#index" className={styles.form}>
            <div className={styles.heading}>
              <h2 className={styles.headingSecondary}>Zapisz się już dziś!</h2>
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Imię"
                id="name"
                name="name"
                value={this.state.name}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="name" className={styles.formLabel}>
                Imię
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="tel"
                className={styles.formInput}
                placeholder="Numer telefonu:"
                id="phone"
                name="phone"
                value={this.state.phone}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="phone" className={styles.formLabel}>
                Numer telefonu
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                className={styles.formInput}
                placeholder="Email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="email" className={styles.formLabel}>
                Email
              </label>
            </div>
            {this.props.isDanceInPairs && (
              <div className={styles.formGroup}>
                <input
                  type="text"
                  className={styles.formInput}
                  placeholder="Imię partnera/partnerki"
                  id="partnersName"
                  name="partnersName"
                  value={this.state.partnersName}
                  onChange={(event) => this.handleUserInput(event)}
                  required
                />
                <label htmlFor="partnersName" className={styles.formLabel}>
                  Imię partnera/partnerki
                </label>
              </div>
            )}
            <div className={styles.formGroup}>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Nazwa tańca"
                id="danceName"
                name="danceName"
                value={this.state.danceName}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="danceName" className={styles.formLabel}>
                Nazwa tańca
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Dzień tygodnia"
                id="day"
                name="day"
                value={this.state.day}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="day" className={styles.formLabel}>
                Dzień tygodnia
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                className={styles.formInput}
                placeholder="Godzina"
                id="hour"
                name="hour"
                value={this.state.hour}
                onChange={(event) => this.handleUserInput(event)}
                required
              />
              <label htmlFor="hour" className={styles.formLabel}>
                Godzina
              </label>
            </div>
            <div className={styles.btnContainer}>
              <button
                className={[styles.btn, styles.btnRed].join(" ")}
                onClick={(event) => this.submitForm(event)}
              >
                Zapisz się &rarr;
              </button>
            </div>
          </form>
        </div>
      </div>
    );

    if (this.state.submitted && this.state.error !== true) {
      formContent = (
        <div className={styles.book}>
          <div className={styles.bookForm}>
            <div className={styles.bookForm}>
              <form action="#index" className={styles.form}>
                <div className={styles.heading}>
                  <h2 className={styles.headingSecondary}>
                    Twoja wiadomość została wysłana. Dziękujemy!
                  </h2>
                </div>
                &nbsp;
                <div className={styles.btnContainer}>
                  <button
                    className={[styles.btn, styles.btnRed].join(" ")}
                    onClick={(event) => this.resubmitForm(event)}
                  >
                    Zapisz się na kolejne zajęcia!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.submitted === false && this.state.error === true) {
      formContent = (
        <div className={styles.book}>
          <div className={styles.bookForm}>
            <div className={styles.bookForm}>
              <form action="#index" className={styles.form}>
                <div className={styles.heading}>
                  <h2 className={styles.headingSecondary}>
                    Wystąpił błąd. Nie udało się wysłać wiadomości! Proszę
                    skontaktuj się telefonicznie: 783952086.
                  </h2>
                </div>
                &nbsp;
                <div className={styles.btnContainer}>
                  <button
                    className={[styles.btn, styles.btnRed].join(" ")}
                    onClick={(event) => this.resubmitForm(event)}
                  >
                    Spróbuj ponownie
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    return formContent;
  }
}

export default Form;
