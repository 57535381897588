import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.module.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import AOS from "aos";
import "aos/dist/aos.css";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import homePageReducer from "./store/reducers/homePageReducer";
import navigationReducer from "./store/reducers/navigationReducer";
import userInterfaceReducer from "./store/reducers/userInterfaceReducer";
import dancesReducer from "./store/reducers/dancesReducer";
import instructorsReducer from "./store/reducers/instructorsReducer";
import pricesReducer from "./store/reducers/pricesReducer";
import scheduleReducer from "./store/reducers/scheduleReducer";
import firstDanceReducer from "./store/reducers/firstDanceReducer";
import individualDancesReducer from "./store/reducers/individualDancesReducer";
import batchPartyReducer from "./store/reducers/batchPartyReducer";
import danceShowReducer from "./store/reducers/danceShowReducer";

import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  homePageReducer: homePageReducer,
  navigationReducer: navigationReducer,
  userInterfaceReducer: userInterfaceReducer,
  dancesReducer: dancesReducer,
  instructorsReducer: instructorsReducer,
  pricesReducer: pricesReducer,
  scheduleReducer: scheduleReducer,
  firstDanceReducer: firstDanceReducer,
  individualDancesReducer: individualDancesReducer,
  batchPartyReducer: batchPartyReducer,
  danceShowReducer: danceShowReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

AOS.init();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
